.modal-enter {
  transform: translateX(100%);
}

.modal-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.modal-exit {
  transform: translateX(0);
}

.modal-exit-active {
  transform: translateX(100%);
  transition: transform 300ms;
}